import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from "@mui/material/Typography";

const buildImageList = (max) => {
    const imageData = [];
    for (let i = 1; i < max; i++) {
        imageData.push(
            {
                img: `https://objects-us-east-1.dream.io/meselect/portfolio_${i}.jpg`,
                title: '',
            }
        )
    }
    return imageData;
}

function Portfolio() {
    const itemData = buildImageList(17);
    return (
        <>
            <Typography variant={"h4"} sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#273898',
                marginTop: 3,
                marginBottom: 3,
            }}>
                Previous Projects
            </Typography>
            <Box sx={{ width: '100%', height: 500, overflowY: 'scroll'}}>
                <ImageList variant="masonry" cols={4} gap={8} sx={{ margin: 1}}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </>

    );
}

export default Portfolio;
