import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import './contact.css'

function Contact() {
    return (
        <section className={'contact'}>
            <Box sx={{
                flexGrow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                flexDirection: 'row',
                p: 6,
                m: 1,
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>Moore&apos;s Electric Services</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>655 Main Street</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>Reisterstown, MD 21136</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Link href={'tel://443-271-1702'}>
                                443-271-1702
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>robert@meselecservices.com</Box>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}

export default Contact;
