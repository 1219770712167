import React from 'react'
import './app.css';
import '../nav/nav.jsx'

import Contact from "../contact/contact";
import Nav from "../nav/nav";
import Services from "../services/services"
import Portfolio from "../portfolio/porfolio"

function App() {
    return (
        <div className="App">
            <Nav/>
            <Services/>
            <Portfolio/>
            <Contact/>
        </div>
    );
}

export default App;
