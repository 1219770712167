import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Bolt from '@mui/icons-material/Bolt';
import './services.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

const LatoSource = 'https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap';

const theme = createTheme({
    typography: {
        fontFamily: 'Lato, Helvetica',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Lato';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Raleway'), local('Raleway-Regular'), url(${LatoSource}) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
              `,
        },
    },
});

const servicesListItems = [
    '24/7 Emergency Calls',
    'Electrical troubleshooting and repairs',
    'Electrical Services Upgrades',
    'New residential, commercial, and industrial electrical installations',
    'Data wiring upgrades',
    'Petroleum controls and instrumentation',
    'Trenching and backhoe services for all of your underground work needs',
    'Pole light installations, repairs and LED retrofits',
    'Bucket Truck with 36ft working height',
];

function Services() {
    return (
        <section className={"services"}>
            <ThemeProvider theme={theme}>
                <Typography variant={"h4"} sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#273898'
                    }}>
                    Services
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ minHeight:375, padding: 2, margin: 2 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold'}}>
                                    Systems Maintenance
                                </Typography>
                                <List>
                                    {
                                        servicesListItems.map((listItem) => (
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <Bolt/>
                                                </ListItemIcon>
                                                <ListItemText sx={{ marginLeft: -2}}>{listItem}</ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ minHeight:375, padding: 2, margin: 2 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold'}}>
                                    Electrical Contracting
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    With all the changes, you need someone who can keep up with the new electrical codes.
                                    I am a master electrician, Maryland license number 11250. We complete all electrical work
                                    to the newest safety codes for your protection and for the efficiency of getting
                                    inspections passed on the first call. This keeps the project flowing so that you can get
                                    it done faster. Please call for any electrical projects you may need completed.
                                </Typography>

                                <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold', marginTop: 5.8}}>
                                    Instrumentation
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    I have over 30 years in electrical experience and can assure that all work is
                                    completed in a workmanlike fashion to the customer's satisfaction. Once we are
                                    contracted to work for our customers, I personally guarantee your experience will be
                                    a good one. Please don&#39;t hesitate to call for any work you may need.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </section>
    );
}

export default Services;
