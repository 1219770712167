import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './nav.css'

function Nav() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={basicTheme}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" color="inherit" component="div">
                            Moore's Electric Services
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </Box>
    );
}

const basicTheme = createTheme({
    palette: {
        primary: {
            main: '#273898',
        },
    },
    typography: {
        fontFamily: [ 'Montserrat', 'Arial', 'Sans-Serif'].join(',')
    },
});

export default Nav;
